@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
* {
  /* font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

body {
  margin-top: 56px;
  color: rgb(99, 99, 99);
}

p {
  color: #000;
  margin: 1.5rem 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.09rem;
}

.welcome-p {
  font-size: 20px;
  line-height: 1.7rem;
  letter-spacing: 0.04rem;
}

.latest-h {
  margin-top: 3rem;
}

.container {
  max-width: 900px;
  min-width: 340px;
}

.container-nav {
  min-width: 383px;
}

.navbar-brand {
  font-weight: 500;
  margin-right: 0.7rem;
}

.navbar-toggler {
  border: none;
}

.card-title {
  font-weight: 500;
}

.card-text {
  color: #000;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.7rem;
  letter-spacing: 0.08rem;
}

.btn {
  font-family: 'Roboto', sans-serif;
}

.view-more:hover {
  background-color: #ebebeb;
}

.text-muted {
  font-size: 14px;
}

